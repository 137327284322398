import React from 'react';

import Button from '../../Button/';
import './index.css';

export default () => {
  return (
    <section className="approach-flexible root-padding">
      <h2 className="c-title">Flexible arrangements</h2>
      <div className="flex">
        <div>
          <span />
          <p className="main">We Build Custom Software</p>
          <p className="content">
            You say the word, and our team becomes your team. You get complete
            software solutions without the hassle of hiring, managing, and
            risk-taking.
          </p>
        </div>
        <div>
          <span />
          <p className="main">We Extend Your Product Team</p>
          <p className="content">
            Already have a team, but need acceleration or technical expertise?
            We can integrate seamlessly into your current product team and
            enhance what you’ve currently got.
          </p>
        </div>
      </div>
    </section>
  );
};
