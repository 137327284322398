import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Steps from '../../components/approachSections/Steps';
import Flexible from '../../components/approachSections/Flexible';
import People from '../../components/approachSections/People';

const pageTitle = 'Our Approach to Software Development | Devetry';
const pageDescription =
  'What makes Devetry different? Our approach to software development, strategy, design, and innovation.';

const ApproachPageTemplate = () => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('our-approach');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Steps />
        <Flexible />
        <People />
      </div>
    </>
  );
};

const ApproachPage = ({ data }) => {
  return <ApproachPageTemplate />;
};

ApproachPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ApproachPage;

export const pageQuery = graphql`
  query ApproachPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Approach/index" } }) {
      frontmatter {
        templateKey
      }
    }
  }
`;
