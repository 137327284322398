import React from 'react';

import { Link } from 'gatsby';

import './index.css';
import orangeEllispe from '../../../img/orange-ellipse.svg';
import peopleFirst from '../../../img/people-first@2x.png';
import peopleFirstMobile from '../../../img/set-us-apart-3.jpg';

export default () => {
  return (
    <section className="approach-people secondary-padding">
      <div className="left">
        <h2 className="c-title">People first</h2>
        <p className="content">
          You might be asking how? How do we deliver exceptional value and keep
          it pleasant for all parties? 
          <br/>
          <br/>
          It’s our philosophy of people-first. 
          <br/>
          <br/>
          Our culture of respect--for one’s ideas, time, and opinions--allows us to
          attract top talent and keep them for the long haul. This creates a
          happy team that is here for your entire project.
        </p>
        <Link className="btn" to="/people">
          Meet our team
        </Link>
      </div>
      <div className="right">
        <span className="blue-dot" />

        <img
          className="orange-ellipse"
          src={orangeEllispe}
          alt="Ellipse"
        />
        <img
          className="photo"
          src={peopleFirst}
          alt="People first"
        />
        <img
          className="mobile-photo"
          src={peopleFirstMobile}
          alt="People first"
        />
      </div>
    </section>
  );
};
