import React from 'react';

import './index.css';
import centerDots from '../../../img/approach-dots-center.svg';
import approach0 from '../../../img/approach-0.svg';
import approach1 from '../../../img/approach-1.svg';
import approach2 from '../../../img/approach-2.svg';
import approach3 from '../../../img/approach-3.svg';
import approach4 from '../../../img/approach-4.svg';
import approach5 from '../../../img/approach-5.svg';

export default () => {
  return (
    <section className="root-padding approach-steps">
      <img
        className="center-dots"
        src={centerDots}
        alt="Dots"
      />
      <span className="first-dot" />
      <div className="approach-container">
        <h1 className="heading">A fresh approach to technology.</h1>
        <p className="subtitle">
          Using a combination of compassion, respect, and serious skill, we
          deliver award-winning software solutions.
        </p>
      </div>
      <img
        className="first-ill"
        src={approach0}
        alt="Approach"
      />
      <div className="numbers-container">
        <div className="number-container align-self-end">
          <p className="c-number">01</p>
          <p className="c-title">Understanding your vision</p>
          <p className="content">
            If you think you’ve been asked a lot of questions before, wait until
            you meet our team. 
            <br/>
            <br/>
            We start with in-depth discovery and arrive at
            the roots of your challenge. That’s the only way to fully understand
            your vision, position, requirements, and potential.
          </p>
        </div>
        <img
          className="second-ill"
          src={approach1}
          alt="Approach"
        />

        <div className="number-container ">
          <p className="c-number align-self-end">02</p>
          <p className="c-title ">Shape your strategy</p>
          <p className="content ">
            From there, we’re off.
            <br />
            <br />
            We consider your competition, position in the market, current
            organizational structure, technology, and growth potential of your
            software. The perfect solution for your business is crafted for your
            software strategy.
          </p>
        </div>
        <img
          className="third-ill"
          src={approach2}
          alt="Approach"
        />

        <div className="number-container align-self-end">
          <p className="c-number ">03</p>
          <p className="c-title ">Design and build</p>
          <p className="content ">
            Execution takes place in-house by our full-time, local team.
            <br />
            <br />
            Keeping you on-time, on-budget, and in the loop are our objectives. 
            You’ll work with not only our business analysts but designers, tech leads,
            and technology specialists. With transparency in your project and
            frequent updates, you’ll be able to sit back and relax.
          </p>
        </div>
        <img
          className="four-ill"
          src={approach3}
          alt="Approach"
        />
        <span className="second-dot" />
        <img
          className="fifth-ill"
          src={approach4}
          alt="Approach"
        />
        <span className="third-dot" />
        <div className="number-container">
          <p className="c-number align-self-end">04</p>
          <p className="c-title">Test and iterate</p>
          <p className="content">
            While we follow an agile methodology for software development, we
            work with hybrid processes too.
            <br />
            <br />
            Our extensive agile training and scrum certification allow us to
            minimize risk, deliver incremental value, and pivot quickly. Need
            help refining your process? We do that too.
          </p>
        </div>
        <img
          className="sixth-ill"
          src={approach5}
          alt="Approach"
        />
        <span className="four-dot" />
        <div className="number-container align-self-end">
          <p className="c-number ">05</p>
          <p className="c-title ">Innovate</p>
          <p className="content ">
            What’s next for you once your project is complete? We can help you
            measure the impact of your software and continuously innovate.
            Whether you need ongoing support, internal team optimization,
            DevOps, or new products, we are there for you and your team.
          </p>
        </div>
      </div>
    </section>
  );
};
